@media only screen and (min-width: 1024px) {

    .sidenav {
        position: fixed;
        width: 242px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 1;
    }

    .sidenav__logo {
        /* height: 32px; */
        width: 180px;
        margin: 25px;
    }

    .sidenav__buttons {
        display: flex;
        flex-direction: column;
    }

    .sidenav__button {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #0F1C33;
        background: 0;
        border: 0;
        margin: 5px 10px 5px 10px;
        padding: 10px 15px 10px 15px;
        border-radius: 15px;
        width: 100%;
        text-decoration: none;
        font-size: 18px;
        font-weight: bolder;
        margin-left: 10px;
    }

    .sidenav__buttonactive {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #374BD2;
        background: 0;
        border: 0;
        border-radius: 15px;
        width: 100%;
        text-decoration: none;
        font-size: 18px;
        margin-left: 10px;
    }

    .sidenav__button:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.128);
    }

    .sidenav__button>span {
        font-size: 18px;
        margin-left: 10px;
    }

    .sidenav__buttonactive>span {
        font-size: 18px;
        margin-left: 10px;
        color: #374BD2;
    }

    .sidenav__buttonactive> {}

    .imghidden {
        display: none;
    }

    .sidenav__hr {
        color: rgb(214, 214, 214);
        width: 180px;
    }
}