.settings {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #F7F8FD;
}

.settings__header {
    background-color: #EFF3FD;
}

.settings__header__text {
    margin: 40px auto 40px 30px;
    font-weight: bold;
    font-size: 22px;
    color: #0F1C33;
}