.spinnerContainer {
    position: fixed;
    display: flex;
    flex: 1;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: white;
    opacity: 0.8;
    z-index: 10;
}

.spinnerInnerContainer {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin: auto;
    z-index: 200;
    width: 100%;
}

.spinnerCentredContainer {
    display: flex;
    margin: auto;
}

.spinnerWorkingText {
    font-weight: bold;
    font-size: 30px;
    color: black;
    display: flex;
    margin: auto
}