.timeline {
    display: flex;
    flex: 0.8;
    flex: 1;
    flex-direction: column;
    background-color: #F7F8FD;
}

.timeline__header {
    background-color: #EFF3FD;
    display: flex;
    flex-direction: row;
    height: 15vh;
}

.timeline__header__text {
    display: flex;
    margin: auto auto auto 30px;
    font-weight: bold;
    font-size: 22px;
    color: #0F1C33;
}

.timeline__header__picContainer {
    display: flex;
    flex-direction: row;
}

.timeline__header__picText {
    display: flex;
    margin: auto 30px auto auto;
    color: #0F1C33;
    font-size: 16px;
    font-weight: bold;
}

.timeline__header__profilePic {
    display: flex;
    border-radius: 1000px;
    height: 50px;
    width: 50px;
    margin: auto 10px auto auto
}

.header__refreshIcon__parsedAt {
    display: flex;
    margin: auto auto auto auto;
    background-color: #ffffff;
    border-radius: 1000px;
    padding: 0.5rem;
}

.header__refreshIcon__parsedAtLoading {
    display: flex;
    margin: auto auto auto auto;
    background-color: #ffffff;
    border-radius: 1000px;
    padding: 0.95rem;
}

.header__refreshIcon__parsedAt:hover {
    cursor: pointer;
    scale: 1.1
}