.verify__outerContainer {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FGradient%201.png?alt=media&token=ba178406-080b-47db-b45c-a2af76b8f3a1&_gl=1*1ipoynl*_ga*ODA3NjkwNDg5LjE2OTQxNTg1ODY.*_ga_CW55HF8NVT*MTY5NzA5NDE1Ni4xMS4xLjE2OTcwOTQxODQuMzIuMC4w");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: auto;
}

.verify__container {
    display: flex;
    height: 100vh;
    width: 800px;
    margin: auto;
}

.verify__rightContainer {
    display: flex;
    margin: auto;
}

.verify__codeOuter {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.verify__input {
    border-radius: 5px;
    height: 50px;
    border-color: #424242;
    border-width: 1px;
    width: 50px;
    margin: 0px 2px 0px 2px;
    text-align: center;
    font-size: 16px;

}

.login__loginButtonactive {
    background-color: #69E199;
    height: 40px;
    border-radius: 5px;
    border: 0px solid black;
    color: white;
    font-size: 14px;
    font-weight: 550;
    margin-top: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}