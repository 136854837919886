.timeline {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #F7F8FD;
}

.timeline__header {
    background-color: #EFF3FD;
    display: flex;
    flex-direction: row;
}

.timeline__header__picContainer {
    display: flex;
    flex-direction: row;
}

.timeline__header__picText {
    display: flex;
    margin: auto 30px auto auto;
    color: #0F1C33;
    font-size: 16px;
    font-weight: bold;
}

.timeline__header__profilePic {
    display: flex;
    border-radius: 1000px;
    height: 50px;
    width: 50px;
    margin: auto 10px auto auto
}

.timeline__dash {
    font-weight: bolder;
    margin: 30px 40px 0px 40px;
    display: flex;
    flex-direction: row;
}

.timeline__dash__text {
    margin: auto auto 0px auto;
    font-size: 18px;
}

.timeline__dash__box_left {
    background-color: white;
    width: 300px;
    height: 200px;
    box-shadow: 0px 0px 50px 0px #ebebeb;
    border-radius: 13px;
    display: flex;
    margin: auto 20px auto auto;
    flex-direction: column;
}

.timeline__dash__box_center {
    background-color: white;
    width: 300px;
    height: 200px;
    box-shadow: 0px 0px 50px 0px #ebebeb;
    border-radius: 13px;
    display: flex;
    margin: auto 20px auto 20px;
    flex-direction: column;
}

.timeline__dash__box_right {
    background-color: white;
    width: 300px;
    height: 200px;
    box-shadow: 0px 0px 50px 0px #ebebeb;
    border-radius: 13px;
    display: flex;
    margin: auto auto auto 20px;
    flex-direction: column;
}

.timeline__dash__container {
    display: flex;
    flex-direction: column;
    margin: auto auto auto auto;
}

.timeline__dash__scoreContainer {
    width: 150px;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.timeline__dash__scoreText {
    display: flex;
    margin: auto;
    color: black
}

.timeline__dash__center {
    display: flex;
    margin: auto auto auto auto;
    flex-direction: column;
}

.timeline__dash__trendingText {
    font-size: 61px
}

.uparrow {
    width: 0;
    height: 0;
    margin: auto 10px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #59CD90;
}

.downarrow {
    width: 0;
    height: 0;
    margin: auto 10px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #F47979;
}

.timeline__dash__trendingContainerimproving {
    height: 150px;
    align-items: center;
    display: flex;
    margin: auto;
    color: #59CD90
}

.timeline__dash__trendingContainerdeclining {
    height: 150px;
    display: flex;
    margin: auto;
    color: #F47979
}

.timeline__dash__reviewContainer {
    display: flex;
    margin: 10px auto auto auto;
    flex-direction: column;
}

.timeline__dash__reviewableText {
    display: flex;
    margin: auto auto 10px auto;
    font-size: 61px;
    color: #1A1C20
}

.timeline__dash__requireReviewText {
    display: flex;
    margin: auto;
    font-size: 18px;
    color: #1A1C20;
    white-space: nowrap;
}

.timeline__dash__requireReviewLink {
    display: flex;
    margin: auto;
    font-size: 16px;
    color: #59CD90;
    border-bottom: solid 1px #59CD90;
    white-space: nowrap;
}

.timeline__dash__right {
    display: flex;
    flex-direction: column;
    margin: auto auto auto auto;
    flex-direction: column;
}

.timeline__parsedAtOuterContainer {
    display: flex;
    padding: 1rem;
    margin: 0px 0px;
}

.timeline__parsedAtContainer {
    display: flex;
    margin: auto 10px auto 0px;
    flex-direction: column;

}

.timeline__parsedAt {
    color: black;
    font-size: 12px;
    margin: 0px auto 5px 0px
}

.timeline__parsedAtTime {
    color: black;
    margin: 0px auto auto 0px;
    font-size: 14px;
    font-weight: bold
}

.timeline__refreshIcon__parsedAt {
    display: flex;
    margin: 0px 0px auto 10px;
    background-color: #EFF3FD;
    border-radius: 13px;
    padding: 0.5rem;
}

.timeline__refreshIcon__parsedAt:hover {
    cursor: pointer;
    scale: 1.1
}

.timeline__gridContainer {
    display: flex;
    margin: 5px auto auto auto;
    height: 60px;
}

.timeline__posts {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 85vh;
    margin: 0px auto 0px auto
}

.timeline__gallery__outer {
    width: 1100px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin: 3rem auto 1rem auto;
    background-color: white;
    border-radius: 13px;
    box-shadow: 0px 0px 50px 0px #ebebeb;
}

.timeline__sortContainer {
    display: flex;
    flex-direction: row;
    margin: 1rem
}

.timeline__sortContainer__outer {
    display: flex;
    flex-direction: row;
    /* margin: 0; */
    padding: 1rem
}

.timeline__sortContainer__inner {
    display: flex;
    flex-direction: column;
    margin: 0px 5px 0px 0px
}

.timeline_sortContainer__text {
    margin: 0px auto 5px 0px;
    color: black;
    font-size: 12px;
    white-space: nowrap;
}

.timeline__sortContainer__sortContainer {
    width: 150px;
    height: 30px;
    border: 1px solid black;
    border-radius: 4px
}

.timeline__emptyPosts {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    height: 70vh;
    background-color: white;
    margin: 3rem auto;
    border-radius: 13px;

}

.timeline__emptyPosts__text {
    display: flex;
    margin: auto auto 0px auto;
    color: #b8b5b5;
    font-weight: 600;
    font-size: 22px;
}

.timeline__lastRefreshed {
    display: flex;
    margin: 0px auto 10px auto;
    color: #808080;
    font-weight: 600;
    font-size: 18px;
}

.timeline__refreshIcon__container {
    display: flex;
    margin: 5px auto auto auto;
    height: 60px;
}

.timeline__refreshIcon__container:hover {
    cursor: pointer;
    scale: 1.1
}




/* Imported from https://codepen.io/GeorgePark/pen/VXrwOP  */
.gallery {
    max-width: 1100px;
    margin: 20px auto;
    background-color: white;
    border-radius: 13px;
    /* 
    display: flex;
    flex-wrap: wrap; */

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* grid-template-rows: repeat(3, 1fr); */
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        ". . ."
        ". . ."
        ". . .";



}