.deleteDialog__outerContainer {
    position: fixed;
    z-index: 20;
    display: flex;
    height: 100vh;
    width: 100vw;
}

.deleteDialog__innerContainer {
    z-index: 20;
    flex-direction: column;
    display: flex;
    margin: auto;
    background-color: white;
    box-shadow: 0px 0px 30px 0px #afaeae;
    border-radius: 13px;
    padding: 30px
}

.deleteDialog__textContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.deleteDialog__text1 {
    margin: 0px auto 0px auto;
    color: black;
    font-size: 20px;
    font-weight: bold;
}

.deleteDialog__text2 {
    margin: 10px auto auto auto;
    color: rgb(136, 136, 136);
    font-size: 18px;
    font-weight: bold;
}

.deleteDialog_buttonContainer {
    display: flex;
    flex-direction: row;
}

.deleteDialog__ignoreButton {
    padding: 10px;
    display: flex;
    background-color: #606060;
    border-radius: 13px;
    margin: 20px auto auto 10px
}

.deleteDialog__deleteButton {
    padding: 10px;
    display: flex;
    background-color: #F47979;
    border-radius: 13px;
    margin: 20px 10px auto auto
}

.deleteDialog__buttonText {
    margin: auto auto auto 0px;
    font-size: 15px;
}

.deleteDialog__ignoreButton:hover {
    cursor: pointer;
    scale: 1.1
}

.deleteDialog__deleteButton:hover {
    cursor: pointer;
    scale: 1.1
}