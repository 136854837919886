.newsFeed__articleOuterContainer {
    display: flex;
    flex-direction: column;
}

.newsFeed__articleInnerContainer {
    display: flex;
    flex-direction: column;
    background-color: beige;
    margin: 5px auto;
    padding: 15px
}

.newsFeed__articleTextTitle {
    display: flex;
    margin: 2px 0px;
    font-size: 12px;
    color: black;
    font-weight: bold;
}

.newsFeed__articleTextPublisher {
    display: flex;
    margin: 2px 0px;
    font-size: 12px;
    color: rgb(131, 131, 131);
    font-weight: bold;
}

.newsFeed__articleText {
    display: flex;
    margin: 2px 0px;
    font-size: 10px;
    color: black
}