.login__container {
    display: flex;
    width: 800px;
    margin: auto
}

.homepage__logoContainer {
    display: flex;
    margin: auto;
    padding: 10px;
    flex-direction: column;
}

.homepage__logo {
    display: flex;
    height: 30px;
    margin: auto auto 20px 0px
}

.homepage__text1 {
    font-size: 41px;
    font-weight: bold;
    margin: 20px auto 10px auto
}

.homepage__text2 {
    font-size: 25px;
    margin: 10px auto 0px auto
}


.login__loginFormContainer {
    background-color: #FAFAFA;
    border-radius: 24px;
    padding: 40px;
    margin-left: 20px;
}

.login__welcomeText {
    color: #0F1C33;
    font-size: 12px;
    /* font-weight: bold; */
    margin: 5px auto 5px 0px;
}

.login__inputLabel {
    color: #0F1C33;
    font-size: 12px;
    margin: 5px auto 5px 0px
}

.login__loginText {
    color: #0F1C33;
    font-size: 25px;
    font-weight: 600;
    margin: 5px auto 5px 0px;
    white-space: nowrap;
}

.login__inputContainer {
    display: flex;
    flex-direction: column;
}

.login__input {
    padding-left: 10px;
    border-radius: 5px;
    height: 25px;
    border-color: #424242;
    border-width: 1px;
    width: 250px;
}

.login__forgotPassword {
    color: #0F1C33;
    font-size: 12px;
    margin: 5px 0px 0px auto
}

.login__forgotPassword:hover {
    cursor: pointer;
    scale: 1.01
}

.login__loginButton {
    background-color: #a1a1a1;
    height: 40px;
    border-radius: 5px;
    border: 0px solid black;
    color: white;
    font-size: 14px;
    font-weight: 550;
    margin-top: 20px;
}

.login__loginButtonactive {
    background-color: #69E199;
    height: 40px;
    border-radius: 5px;
    border: 0px solid black;
    color: white;
    font-size: 14px;
    font-weight: 550;
    margin-top: 20px;
}

.login__loginButtonactive:hover {
    cursor: pointer;
    scale: 1.01
}



.login__newUser {
    color: #0F1C33;
    font-size: 14px;
    margin: 20px auto 0px auto
}

.login__newUser>span {
    color: #69E199;
    font-weight: bold;
}

.login__newUser>span:hover {
    cursor: pointer;
}

.signup__passwordWarning {
    max-width: 250px;
    color: red;
    font-size: 12px;
}

.signup_gridContainer {
    display: flex;
    margin: auto;
    margin: 20px auto auto auto
}

.signup_gridContainerInner {
    display: flex;
    margin: auto;
}