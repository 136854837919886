.test {
    display: flex;
    min-height: 100vh;
    flex: 1
}

.homepage {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100%
}

.homepage__loginContainer {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FGradient%201.png?alt=media&token=ba178406-080b-47db-b45c-a2af76b8f3a1&_gl=1*1ipoynl*_ga*ODA3NjkwNDg5LjE2OTQxNTg1ODY.*_ga_CW55HF8NVT*MTY5NzA5NDE1Ni4xMS4xLjE2OTcwOTQxODQuMzIuMC4w");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: auto;
    height: 100vh;
}

.homepage__navWraper {
    position: relative;
    display: flex;
    flex: 0.3;
    min-width: 250px;
    max-width: 300px;
}

.homepage__timeline {
    display: flex;
    flex: 1;
}