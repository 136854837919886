.homepage {
    display: flex;
    flex-direction: row;
}

.homepage__navWraper {
    position: relative;
    display: flex;
    flex: 0.3;
    min-width: 250px;
    max-width: 300px;
}

.homepage__timeline {
    display: flex;
    flex: 1;
}

.timeline {
    display: flex;
    flex: 0.8;
    flex: 1;
    flex-direction: column;
    background-color: #F7F8FD;
}

.review {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #F7F8FD;
}

.review__header {
    background-color: #EFF3FD;
}

.review__header__text {
    margin: 40px auto 40px 30px;
    font-weight: bold;
    font-size: 22px;
    color: #0F1C33;
}

.review__outerContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: #F7F8FD;
    padding: 2rem;
    height: 100%
}

.review__postContainer {
    display: flex;
    flex: 0.4;
    margin: auto 1rem auto auto;
    padding: 1rem;
    background-color: white;
    height: 80vh;
    border-radius: 13px;
    box-shadow: 0px 0px 30px 0px #ebebeb;
}

.review__postContainerInner {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
}

.review__sentimentScore {
    color: black;
    font-size: 18px;
    font-weight: bold;
    margin: auto auto auto 20px
}

.review__sentimentScoreContainer {
    display: flex;
    height: 55px;
    padding: 1rem 1rem 0rem 1rem;
    margin: 0px auto 0px 0px
}

.review__sentimentProgressContainer {
    display: flex;
    height: 55px;
    margin: 0px 0px auto 0px
}

.review__img {
    display: flex;
    width: 100%;
    /* padding: 0.5rem 1rem 0.5rem 1rem; */
    margin: 10px auto 0px auto
}

.review__imgContainer {
    padding: 1rem
}

.review__postImgLikes {
    font-weight: bold;
    font-size: 15px;
    color: #00261C;
    margin: 0px auto 0px 0px;
    padding: 0rem 1rem 0rem 1rem;
}

.review__postImgCaption {
    font-size: 15px;
    color: #00261C;
    margin: 0px auto 0px 0px;
    padding: 0rem 1rem 0rem 1rem;
}

.review__postImgCaption>span {
    font-weight: bold;
}

/* Right hand review container below */

.review__reviewContainer {
    display: flex;
    flex-direction: column;
    flex: 0.6;
    margin: auto auto auto 1rem;
    padding: 1rem;
    background-color: white;
    height: 80vh;
    border-radius: 13px;
    box-shadow: 0px 0px 30px 0px #ebebeb;
}

.review__requiringOuterContainer {
    display: flex;
}

.review__requiringCircle {
    display: flex;
    background-color: black;
    height: 45px;
    width: 45px;
    border-radius: 1000px;
}

.review__unresolved__count {
    display: flex;
    margin: auto 10px 10px 5px;
    font-size: 18px;
    color: black;
    font-weight: bold;
}

.review__requiringContainer {
    display: flex;
    margin: 0px auto 0px 0px;
}



.review__requiringText {
    display: flex;
    color: black;
    font-weight: bold;
    font-size: 22px;
}

.review__sortContainer__outer {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    margin: 0 0 0 0;
}

.review__commentOptionsContainer {
    display: flex;
    flex: 1 1
}

.review__innerReviewContainer {
    display: block;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden
}

.review__commentContainer {
    /* border: 1px solid #979797; */
    height: 120px;
    width: 90%;
    border-radius: 13px;
    padding: 1rem;
    margin: 10px auto;
    border: 1px solid rgb(212, 211, 211);
    /* box-shadow: 0px 0px 3px 0px hsl(0, 0%, 62%); */
    display: flex;
    flex-direction: column;
}

.review__commentText {
    color: #4D4D4D;
    font-size: 15px;
    margin: 0px auto 0px 0px
}

.review__commentTextBold {
    color: #4D4D4D;
    font-size: 15px;
    font-weight: bold;
}

.review__commentDate {
    color: #BDBDBD;
    font-size: 13px;
    margin: 0px 0px 0px auto
}

.review__sentimentContainer {
    display: flex;
    margin: auto auto 0px 0px
}

.review__commentProgressContainer {
    height: 20px;
    width: 20px;
    display: flex;
}

.review__commentSentimentText {
    color: #00261C;
    font-size: 13px;
    white-space: nowrap;
    margin: auto auto auto 10px;
    font-weight: bold;
}

.review__bottomContainer {
    display: flex;
    flex-direction: row;
    margin: auto 0px 0px 0px
}

.review__buttonContainerOuter {
    display: flex;
    flex-direction: row;
}

.review__ignoreButton {
    color: white;
    font-weight: bold;
    background-color: #606060;
    padding: 0.5rem;
    font-size: 11px;
    border: 1px solid #606060;
    border-radius: 4px;
}

.review__ignoreButton:hover {
    cursor: pointer;
    scale: 1.1
}

.review__deleteButton {
    color: white;
    font-weight: bold;
    background-color: #F47979;
    padding: 0.5rem;
    font-size: 11px;
    border: 1px solid #F47979;
    border-radius: 4px;
    margin: 0px 0px 0px 5px
}

.review__deleteButton:hover {
    cursor: pointer;
    scale: 1.1
}

.review__noReviewText {
    display: flex;
    margin: auto;
    color: grey;
    padding: 20px;
    font-weight: 600;
}