.post {
    width: 550px;
    margin: 30px 40px 50px 40px;
}

.post__header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.post__headerAuthor {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bolder;
}

.post__headerAuthor>span {
    color: grey;
    font-size: 15px;
    margin: 0px auto 5px 0px
}

.post__image>img {
    width: 100%;
    border-radius: 6px;
    border: 0.6px solid rgba(128, 128, 128, 0.516);
}

.post__footer {}

.post__footerIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.post__footer__text {
    color: #00261C;
    font-size: 14px;
    font-family: inter;
    font-weight: bold;
    margin: auto auto auto 10px;
}

.post__footer__textLikes {
    color: #00261C;
    font-size: 14px;
    font-family: inter;
    margin: auto auto 5px 5px;
}

.post__footer__iconContainer {
    display: flex;
    flex-direction: row;
    margin: 2px auto 2px auto
}

.post__comments__logo {
    display: flex;
    width: 23px;
}

.postIcon {
    padding: 7px;
    font-size: 30px;
}

.postIcon :hover {
    cursor: pointer;
    scale: 1.01
}

/* Imported from https://codepen.io/GeorgePark/pen/VXrwOP  */
.gallery-item {
    position: relative;
    width: 300px;
    flex: 0 0 2rem;
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 13px;
    color: #fff;
    cursor: pointer;
    box-shadow: 0px 0px 50px 0px #ebebeb;
}